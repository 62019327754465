.container {
    min-height: 100vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.main {
    width: 100vw;
    height: 100vh;

    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.terminal-buttons {
    // border: 1px solid rgba($color: #eee, $alpha: 0.8);
    display: inline-block;
}


$colorBorder: rgba(
    $color: #aaa,
    $alpha: 0.1,
);


.terminal-command-button {
    background-color: transparent;
    border: none;

    border-bottom: 1px solid $colorBorder;
    border-right: 1px solid $colorBorder;


    color: rgba($color: #ddd, $alpha: 1);
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
    font-size: 1em;
    

    cursor: pointer;
    text-decoration: none;

    padding: 0.5rem 1rem;
    padding-top: 0.7rem;


    &:hover:not(.selected) {
        color: rgba($color: #fff, $alpha: 0.8);
        text-shadow:  0 0 5px #eee;
        transition: 0.5s;
    }


    &.selected {
        color: rgba(255, 255, 255, 0.8);
        font-weight: bold;
        border: none;
        border-right: 1px solid $colorBorder;

        &:hover {
            background-color: transparent;
            cursor: default;
        }
    }
}


.none {
    opacity: 0;
    transition-delay: 5s;
}