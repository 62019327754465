.sky {
    overflow: hidden;
    position: fixed;
    width: 100vw;
    height: 100vh;
    // top: 0;
    z-index: -1;
    // border: 1px solid white;
    transition: 4s;
}

#stars {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -9999;
    // border: 1px solid red;
}

$base-glow-color: #fff;
$primary-glow-color: #1c19c7;
$secondary-glow-color: #24effd;

.star {
    position: absolute;
    animation: glow 3s ease-in-out infinite alternate;
    animation: fade 3s ease-in-out infinite alternate;

    animation-name: glow, fade;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;

    @for $i from 0 to 80 {
        $t: (1 + 0.01 * random(250)) * 1s;

        &:nth-child(#{$i + 1}) {
            transform: translate(random(110) * 1vw, random(200) * 1vh);

            color: rgba($color: $base-glow-color, $alpha: 0.8);

            font-size: 1px * random(9) + 6;

            animation-duration: $t;
            animation-delay: -0.01 * random(100) * $t;
        }
    }
}

@keyframes glow {
    from {
        text-shadow: 0 0 10px $base-glow-color, 0 0 20px $base-glow-color, 0 0 30px $primary-glow-color,
            0 0 40px $primary-glow-color, 0 0 50px $primary-glow-color, 0 0 70px $primary-glow-color;
    }

    to {
        text-shadow: 0 0 20px $base-glow-color, 0 0 30px $secondary-glow-color, 0 0 40px $secondary-glow-color,
            0 0 50px $secondary-glow-color, 0 0 60px $secondary-glow-color, 0 0 80px $secondary-glow-color;
    }
}

@keyframes fade {
    from {
        opacity: 0.2;
    }

    to {
        opacity: 1;
    }
}
