.about {
    padding: 2rem 1rem;
    height: 100%;
    display: block;

    .bold {
        font-weight: bold;
        display: block;
        font-size: 15px;
    }

    .container-text {
        flex: 1;
        .text {
            padding-top: 1.5rem;
            padding-right: 2rem;
            text-align: justify;
        }
    }

    img {
        flex: 1;
        border-radius: 50%;
    }

    .pond {
        width: 100vw;
        height: 100vh;
        z-index: -1;
        left: 0;
        top: 0;

        position: fixed;

        display: flex;
        align-items: center;
        justify-content: center;
        
        .koi {

            text-align: center;
            justify-content: center;
            display: flex;

            font-family: monospace;
            white-space: pre;
            font-size: 0.75em;
            text-overflow: clip;

            font-weight: lighter;
            line-height: unset !important;
            opacity: 0.75;
            width: 50%;

        }
    }
}
