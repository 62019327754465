$border-color: #fff;

.projects {
    height: 100%;

    position: relative;
    text-align: justify;

    padding: 1rem;
    font-size: 11px;

    .bold {
        font-weight: bold;
        display: block;
        font-size: 15px;
    }

    a {
        color: #fff;
        text-decoration: none;
    }

    .cardlist {
        .item {
            margin-bottom: 1.5rem;
            border-left: 2px solid rgba($color: $border-color, $alpha: 0.7);
            padding: 1rem;
            padding-bottom: 0.5rem;
            transition: 0.1s;
            font-weight: normal;
            font-size: 12px;
            position: relative;

            h5 {
                font-size: 14px;
            }

            .title {
                font-weight: bold;
                display: block;
                font-size: 14px;
            }
            &:hover {

                // background-color: rgba($color: #000000, $alpha: 0.3);
                // box-shadow: 0px 0px 5px 3px rgba($color: $border-color, $alpha: 0.5);
                // border: 2px solid rgba($color: #fff, $alpha: 0.7);
                background-color: rgba($color: #444, $alpha: 0.3);

                text-shadow: none;
            }

            .chips {
                display: block;
                text-align: right;
                padding-top: 0.7rem;

                .chip {
                    display: inline-block;
                    font-size: 11px;
                    padding: 0.3rem 1rem;
                    margin: 5px;
                    border-radius: 12px;
                    background: rgba(5, 5, 5, 0.37);
                    transition: 0.1s;
                }
            }

            .block {
                color: rgba($color: #fff, $alpha: 0.8);

                svg {
                    width: 0.7rem;
                    height: 0.7rem;
                }

                i {
                    margin-right: 1rem;
                }
            }
        }
    }
}
