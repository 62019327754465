.terminal {
    width: 85vw;
    max-width: 1000px;
    height: 70vh;

    border-radius: 5px;
    box-shadow: 0px 0px 10px 7px rgba($color: #000000, $alpha: 0.35);
    background-color: rgba($color: #000000, $alpha: 0.3);

    display: flex;
    position: relative;
    flex-direction: column;

    padding: 0.5rem;
    padding-top: 0;

    .command {
        padding: 0.5rem 0;
        font-size: 13px;
        height: 5vh;
        color: rgba($color: #eee, $alpha: 0.8);

        transition: 2s;

        &.command-bottom {
            color: #eee;

            border-radius: 3px;
            padding: 5px 10px;
        }
    }

    .content {
        height: 60vh;
        overflow-y: auto;
        transition: 1s;
    }

    $colorBorder: rgba(
        $color: rgb(94, 94, 94),
        $alpha: 0.1,
    );

    .header {
        display: flex;
        justify-content: space-between;
        padding-bottom: 0.5rem;

        .right-buttons {
            display: flex;
            border-bottom: 1px solid $colorBorder;
            flex: 1;
            justify-content: flex-end;

            .right-buttons-container {
                button {

                    background-color: transparent;
                    color: rgba($color: rgb(255, 255, 255), $alpha: 0.5);
                    border: none;
                    cursor: pointer;
                    padding-top: 0.7rem;

                    &:hover {
                        color: rgba($color: rgb(255, 255, 255), $alpha: 0.8);
                        text-decoration: underline;
                    }

                    &.selected {
                        font-weight: bold;
                    }
                }
            }
        }
    }
}

@keyframes blink {
    0.01% {
        opacity: 0;
    }
    50% {
        opacity: 0;
    }
    50.01% {
        opacity: 1;
    }
}

.blink {
    opacity: 1;
    animation: blink 1.5s linear infinite;
    font-size: 1rem;
    font-weight: bold;
}
