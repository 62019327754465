@import url("https://fonts.googleapis.com/css2?family=Comfortaa:wght@500&family=Source+Code+Pro&display=swap");

body {
    margin: 0;
    padding: 0;

    line-height: 1.6;
    font-size: 14px;
    background: linear-gradient(45deg, rgb(25, 3, 46) 10%, rgb(13, 33, 88) 70%, rgb(3, 95, 148) 100%);

    color: #fff;
    font-family: "Source Code Pro", monospace;
    scroll-behavior: smooth;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

h1,
h2,
h3,
h4,
h5 {
    font-family: "Comfortaa", cursive;
}

$link-color: #fff;

a {
    text-decoration: underline;
    color: $link-color;
    font-weight: 900;
    cursor: pointer;

    &:hover {
        text-shadow: 0px 0px 10px rgba($color: $link-color, $alpha: 0.8);
    }
}

* {
    box-sizing: border-box;
    margin: 0;
}

::-webkit-scrollbar {
    height: 6px; /* height of horizontal scrollbar ← You're missing this */
    width: 6px; /* width of vertical scrollbar */
    border: 1px solid rgba($color: #000, $alpha: 0.3);
    z-index: 5;
}

/* Track */
::-webkit-scrollbar-track {
    background: linear-gradient(180deg, rgb(3, 1, 19) 25%, rgb(21, 36, 51) 75%);
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #8cadad;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #274963;
}
