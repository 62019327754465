
$base-glow-color: #fff;

.card {
    // border: 1px solid white;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    flex-direction: column;

    .title {
        z-index: 2;

        font-weight: bold;
        font-size: 37px;
        text-align:center;
        color: rgba($color: $base-glow-color, $alpha: 0.9);
        text-shadow: 0 0 10px $base-glow-color;
        word-spacing: 3px;
        letter-spacing: 2px;

        i {
            display: inline-block;
            height: 40px;
            padding-left: 7px;
            border-right: 4px solid rgba($color: $base-glow-color, $alpha: 0.7);
            animation: total-fade 1s infinite alternate;
        }
    }

    .subtitle {
        font-size: 14px;
        text-align: center;
        opacity: 0.8;
        color: $base-glow-color;
    }

    .particles {
        position: absolute;
        width: 100%;
        height: 100%;
    }

    .contacts {
        z-index: 1;
        width: 100%;
        min-height: 40px;
        margin-top: 1.3rem;
        display: flex;
        text-align: center;
        justify-content: center;
        font-size: 13px;
        align-items: center;

        a {
            display: inline-flex;
            color: $base-glow-color;
            margin: 0.5rem;
            opacity: 0.5;
            transition: 0.2s;

            &:hover {
                opacity: 1;
            }
        }

        svg {
            width: 1.7rem;
            height: 1.7rem;
            fill: #fff;
        }
    }
}
